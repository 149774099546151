<template>
    <v-card>
      <v-card-title class="justify-space-between mb-0 py-7">
        <span class="text-h5"><strong>Hospital Wise Report</strong></span>
      </v-card-title>

      <v-row class="ml-2 mr-2 mb-3">

        <v-col cols="12" md="3">
          <v-autocomplete 
            v-model="filter.hospital_name"
            :items="hospitalNames" 
            outlined dense hide-details
            label="Hospital Name">
          </v-autocomplete>
       </v-col>

        <!-- Start date template -->
        <v-col cols="12" md="3">
          <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="filter.date_from"
            transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="dateFormateFrom" label="Start Date : DD-MM-YYYY" readonly outlined hide-details
                dense v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="filter.date_from" no-title scrollable @change="checkStartDate">
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="$refs.menu.save('')">Clear </v-btn>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(filter.date_from)"> OK </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
  
        <!-- End data template -->
        <v-col cols="12" md="3">
          <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" :return-value.sync="filter.date_to"
            transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="dateFormateTo" label="End Date : DD-MM-YYYY" readonly outlined dense hide-details
                v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="filter.date_to" no-title scrollable :min="this.filter.date_from" :disabled="disabledEndDate">
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="$refs.menu1.save('')">Clear </v-btn>
              <v-btn text color="primary" @click="menu1 = false"> Cancel </v-btn>
              <v-btn text color="primary" @click="$refs.menu1.save(filter.date_to)"> OK </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
       
        <v-col cols="12" md="3" class="pl-0">
          <v-btn color="primary" class="mr-2" @click="reportExport()"> Export </v-btn>
          <v-btn color="primary" @click="refreshDate()">Reset</v-btn>
        </v-col>

      </v-row>
      
      <v-snackbar v-model="snackbar" :timeout="timeout">
        {{ snackbarText }}
  
        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </template>
  
  <script>
  import HospitalService from '@/service/Counsellor.service'
  import FileSaver from 'file-saver'
  import { api } from '@/config/config'
  import moment from 'moment'
  import { format, parseISO } from 'date-fns'
  const localStoragedata = (JSON.parse(localStorage.getItem('token')) === null) ? window.location.href = '/' : JSON.parse(localStorage.getItem('token'));
  const token = localStoragedata.token
  export default {
    data() {
      return {
        path: [],
        hospitalNames: [],
        menu: false,
        menu1: false,
        listLoading: false,
        totallist: 0,
        snackbar: false,
        snackbarText: '',
        timeout: 2000,
        disabledEndDate: true,
        date: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
        filter: {
          hospital_name: '',
          date_from: '',
          date_to: ''
        },
        fileName: 'USER-LIST-' + moment().valueOf(),
      }
    },

    computed: {
      dateFormateTo () {
        return this.filter.date_to ? moment(this.filter.date_to).format('DD-MM-YYYY') : ''
      }, 
      dateFormateFrom () {
        return this.filter.date_from ? moment(this.filter.date_from).format('DD-MM-YYYY') : ''
      }, 
      checkStartDate(){
        if(this.filter.date_from != ''){
          this.disabledEndDate = false
        }else{
          this.disabledEndDate = true
        }
      }
    },

    watch: {
      options: {
        handler() {
          this.GetHospital()
        },
        deep: true,
      },
    },
  
    async mounted() {
      this.GetHospital()
    },
  
    methods: {

      async GetHospital() {
        const service = new HospitalService()
        let response = await service.HospitalList()
        if (response) {
          this.hospitalNames = (response.data).map((d)=>d['hospital_name'])
        } else {
          this.hospitalNames = []
        }
      },
  
      refreshDate() {
        this.filter.date_from = '';
        this.filter.date_to = '';
        this.filter.hospital_name = '';
      },

      async reportExport() {
        if(this.filter.hospital_name == '' || this.filter.hospital_name == null || this.filter.hospital_name == undefined){
            this.snackbarText = 'Please Select Hospital Name',
            this.snackbar = true
            return false
        }
        if(this.filter.date_from == '' || this.filter.date_from == null || this.filter.date_from == undefined){
            this.snackbarText = 'Please Fill Date',
            this.snackbar = true
            return false
        }
        if(this.filter.date_to == '' || this.filter.date_to == null || this.filter.date_to == undefined){
            this.snackbarText = 'Please Fill Date',
            this.snackbar = true
            return false
        }
        this.listLoading = true
        const data = {
          date_from: this.filter.date_from,
          date_to: this.filter.date_to,
          hospital_name:  this.filter.hospital_name
        }
        try {
          const response = await api.post(`reports/hospital_wise_report`, data, {
            headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          })
          if (response) {
            this.snackbarText = response.data.message,
            this.snackbar = true
            if(response.data.message != "Records not found"){
              this.path = response.data.path
              var value = `https://api.stage.ebsr.in${this.path}`
              FileSaver.saveAs(value, 'Hospital Wise Report');  
            }
            this.totallist = this.path.length
          } else {
            this.path = []
            this.totallist = 0
          }
          this.listLoading = false
        } catch (e) {
          console.log(e)
        }
      },

    },
  }
  </script>
  